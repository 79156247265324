import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/fontawesome-free-solid';
import TextInput from '../../components/TextField/TextField';
import NumberInput from '../../components/NumberBox/NumberBox';
import SelectBox from '../../components/SelectBox/SelectBox';
import { Entry } from '../../redux/entrySlice';

import { TableContext } from '../../contexts/tableContext';
import styles from './createInterventions.module.scss';
import { REACT_APP_API_FETCH_CATEGORYS_DROPDOWN } from '../../redux/apiConstants';
import TextAreaField from '../../components/TextArea/TextArea';
import MultipleFileUpload from '../../components/MultipleFileUpload/MultipleFileUpload';
import MultipleVideoUpload from '../../components/MultipleFileUpload/MultipleVideoUpload';

function CreateInterventions() {
  const { dashboardStyle } = useContext(TableContext);
  const [isVisible, setIsVisible] = useState(true);
  const [submiting, setSubmitting] = useState(false);
  const { bodyStyle } = useContext(TableContext);
  const { formthemeStyle } = useContext(TableContext);
  const { setDashboardHeader } = useContext(TableContext);
  const { errormsgStyle } = useContext(TableContext);
  const [category, setCategory] = useState([]);
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [perror, setPerror] = useState(null);
  const [isArticle, setIsArticle] = useState(false);
  const { files, setFiles, videoFiles, setVideoFiles, setResetClick, resetClick } =
    useContext(TableContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    setVideoFiles([]);
    setFiles([]);
    setDashboardHeader('Add BALANCE Library Details');
    const url = REACT_APP_API_FETCH_CATEGORYS_DROPDOWN; // api url
    fetch(url)
      .then((resp) => resp.json()) // calling url by method GET
      .then((resp) => {
        setCategory(resp.data.category.selectdata);
        console.log(resp.data.category.selectdata);
      }); // setting response to state roles
  }, []);
  const dispatch = useDispatch();

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const timeRegex = /^\d{1,3}:\d{2}(:\d{2})?$/;
  async function onSubmitdata(data) {
    setIsVisible(false);
    let newFiles = [];
    if (isArticle) {
      newFiles = [files];
    } else {
      newFiles = [files, videoFiles];
    }
    setPerror(null);
    setError(null);
    console.log('newFiles', newFiles);
    if (data.duration && !timeRegex.test(data.duration)) {
      setError('Time format is only allowed for duration, with the following format: hh:mm:ss.');
      setIsVisible(true);
      document.getElementById('duration').focus();
      return;
    }
    if (isArticle) {
      if (newFiles.length <= 0) {
        setPerror('Image is required');
        setIsVisible(true);
        return;
      }
      if (newFiles.length > 0) {
        const imageFiles = newFiles.filter((r) => r && r.type && r.type.startsWith('image'));

        if (imageFiles.length <= 0) {
          setPerror('Image is required');
          setIsVisible(true);
          return;
        }
      }
    }

    if (!isArticle) {
      if (newFiles.length <= 0) {
        setPerror('Image and Video files are required');
        setIsVisible(true);
        return;
      }
      if (newFiles.length > 0) {
        const imageFiles = newFiles.filter((r) => r && r.type && r.type.startsWith('image'));
        const videoFilesCheck = newFiles.filter((r) => r && r.type && r.type.startsWith('video'));

        if (imageFiles.length <= 0 || videoFilesCheck.length <= 0) {
          let errorMessage = '';
          if (imageFiles.length <= 0 && videoFilesCheck.length <= 0) {
            errorMessage = 'Image and Video files are required';
          } else if (imageFiles.length <= 0) {
            errorMessage = 'Image is required';
          } else {
            errorMessage = 'Video is required';
          }
          setPerror(errorMessage);
          setIsVisible(true);
          return;
        }
      }
    }

    setSubmitting(true);
    setError(null);
    const apiData = {
      title: data.title,
      category: data.category.label,
      description: data?.description ? data?.description : '',
      duration: data?.duration ? data?.duration : '',
      status: true,
      isArticle,
      File: newFiles,
      points: data?.points ? data?.points : 0,
    };

    data.actionUrl = 'interventions';
    data.actionMethod = 'post';
    data.apiData = apiData;
    await dispatch(Entry(data)).then((resp) => {
      setSubmitting(false);
      if (resp.payload.code === 200) {
        navigate(-1);
        toast.success('BALANCE Library creation successfull');
        setVideoFiles([]);
        setFiles([]);
      } else if (resp.payload.code === 401 || resp.payload.code === 400) {
        setError(resp.payload.message);
      } else {
        setError('Something went wrong!');
      }
    });
  }
  const interventionTypeHandler = () => {
    setIsArticle(!isArticle);
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 3000);

    // Clear the timer when the component unmounts or when message changes
    return () => clearTimeout(timer);
  }, [perror, error]);
  return (
    <div className={styles[bodyStyle]}>
      <div
        className={styles[dashboardStyle]}
        style={{ minHeight: '100vh' }}
        id={styles.dashboardcont2}
      >
        <div className={styles[formthemeStyle]} id={styles.addform}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            value="Back"
            onClick={() => {
              navigate(-1);
            }}
            className={styles.arrowback}
          />
          <div className="container-fluid mt-5">
            <form onSubmit={handleSubmit(onSubmitdata)}>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <TextInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="Title "
                    fieldName="title"
                    placeHolder="Enter Title"
                    register={register}
                    errors={errors}
                    isRequired
                    mandatory
                    maximLength={50}
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <SelectBox
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    name="category"
                    label="Category"
                    labelstyle={styles.formlabel}
                    control={control}
                    register={register}
                    values={category}
                    errors={errors}
                    placeholder="Choose Category"
                    mandatory
                    isRequired
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <TextAreaField
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    fieldName="description"
                    label="Description "
                    register={register}
                    errors={errors}
                    placeHolder="Description"
                    minimLength={false}
                    isRequired
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <TextInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="Duration "
                    fieldName="duration"
                    placeHolder="Enter Duration in format hh:mm:ss"
                    register={register}
                    errors={errors}
                    id="duration"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <NumberInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="Points"
                    fieldName="points"
                    placeHolder="Enter Points"
                    register={register}
                    errors={errors}
                    id="points"
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <label htmlFor="isArticle" className={styles.formlabel}>
                    Article
                  </label>
                  <div className="form-field">
                    <input
                      type="checkbox"
                      id="custom-checkbox"
                      style={{
                        marginRight: '5px',
                        width: '20px', // Increase the width
                        height: '20px',
                      }}
                      checked={isArticle}
                      onChange={interventionTypeHandler}
                    />
                  </div>
                </div>
              </div>
              <div className="row py-3">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <label htmlFor="image" className={styles.formlabel}>
                    Image <span style={{ color: 'red' }}>*</span> : <span />
                  </label>
                  <MultipleFileUpload id="imageupload" accept={'image/*'} />
                </div>
                {!isArticle && (
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <label htmlFor="video" className={styles.formlabel}>
                      Video <span style={{ color: 'red' }}>*</span> : <span />
                    </label>

                    <MultipleVideoUpload id="videoupload" accept={'video/*'} />
                  </div>
                )}
              </div>
              {perror && (
                <p
                  style={{ display: isVisible ? 'block' : 'none' }}
                  className={styles[errormsgStyle]}
                >
                  {perror}
                </p>
              )}

              <div className="d-flex">
                <input
                  className={styles.formbtn}
                  type="submit"
                  disabled={submiting}
                  value={submiting ? 'Please wait..' : 'Submit'}
                />
                <input
                  className={styles.resetbtn}
                  type="button"
                  value="Reset"
                  onClick={() => {
                    reset();
                    setIsArticle(false);
                    setFiles([]);
                    setVideoFiles([]);
                    setResetClick(!resetClick);
                  }}
                />
              </div>
              <br />
              {error && (
                <h6
                  style={{ display: isVisible ? 'block' : 'none', float: 'right' }}
                  className={styles[errormsgStyle]}
                >
                  {error}
                </h6>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateInterventions;
