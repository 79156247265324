import React from 'react';
import PropTypes from 'prop-types';

function NumberInput({
  fieldName,
  register,
  errors,
  isRequired,
  placeHolder,
  defaultValue,
  className,
  labelstyle,
  labeldarkstyle,
  classNamedark,
  maximLength,
  minimLength,
  label,
  mandatory,
  id,
}) {
  let classNames = labelstyle;
  let classNamess = className;

  if (localStorage.getItem('theme') === 'dark') {
    classNames = labeldarkstyle;
    classNamess = classNamedark;
  }
  return (
    <div className="form-field">
      <label htmlFor={fieldName} className={classNames ?? labelstyle}>
        {label ?? 'Number field'}
      </label>
      {mandatory || isRequired ? <span style={{ color: 'red' }}>*</span> : <span />}
      <input
        id={id}
        type="text"
        defaultValue={defaultValue}
        className={classNamess ?? className}
        placeholder={placeHolder}
        onKeyPress={(event) => {
          if (!/^[0-9]+$/.test(event.key)) {
            event.preventDefault();
            errors[fieldName].message = 'Cannot accept Characters';
          }
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...register(fieldName ?? 'Number', {
          required: {
            value: isRequired,
            message: `${label} is required`,
          },
          maxLength: {
            value: maximLength ?? 10,
            message: `Value must be maximum ${maximLength ?? 10} digit value.`,
          },
          minLength: {
            value: minimLength ?? 2,
            message: `Value must be minimum ${minimLength ?? 1} digit value.`,
          },
        })}
      />

      <p style={{ color: 'red' }}>
        {' '}
        {
          // Shows if error exist
          errors[fieldName] && errors[fieldName].message
        }{' '}
      </p>
    </div>
  );
}

NumberInput.propTypes = {
  fieldName: PropTypes.element.isRequired,
  register: PropTypes.element.isRequired,
  errors: PropTypes.element.isRequired,
  placeHolder: PropTypes.element,
  isRequired: PropTypes.element,
  maximLength: PropTypes.element,
  minimLength: PropTypes.element,
  label: PropTypes.element,
  mandatory: PropTypes.element,
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  labeldarkstyle: PropTypes.string,
  classNamedark: PropTypes.string,
  labelstyle: PropTypes.string.isRequired,
  id: PropTypes.string,
};
NumberInput.defaultProps = {
  mandatory: false,
  isRequired: false,
  placeHolder: 'Enter Value',
  maximLength: 10,
  minimLength: 1,
  label: 'Number Input',
  classNamedark: null,
  labeldarkstyle: null,
  className: 'default-input-class',
  defaultValue: null,
  id: '',
};
export default NumberInput;
