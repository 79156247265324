/* eslint-disable react/function-component-definition */
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { SpinnerDotted } from 'spinners-react';
import { toast, ToastContainer } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/fontawesome-free-solid';
import { Entry } from '../../redux/entrySlice';
import styles from './editInterventions.module.scss';
import { TableContext } from '../../contexts/tableContext';
import MultipleFileUpload from '../../components/MultipleFileUpload/MultipleFileUpload';
import MultipleVideoUpload from '../../components/MultipleFileUpload/MultipleVideoUpload';
import 'react-toastify/dist/ReactToastify.css';
import TextInput from '../../components/TextField/TextField';
import TextAreaField from '../../components/TextArea/TextArea';
import { REACT_APP_API_FETCH_CATEGORYS_DROPDOWN } from '../../redux/apiConstants';
import SelectBox from '../../components/SelectBox/SelectBox';
import NumberInput from '../../components/NumberBox/NumberBox';

const InterventionsEdit = React.memo(() => {
  let interventionsId;
  const [submiting, setSubmitting] = useState(false);
  const { dashboardStyle } = useContext(TableContext);
  // const { setDashboardHeader } = useContext(TableContext);
  const { bodyStyle } = useContext(TableContext);
  const { formthemeStyle } = useContext(TableContext);
  const { setDashboardHeader } = useContext(TableContext);
  const { errormsgStyle } = useContext(TableContext);
  const params = useParams();
  const [user, setUser] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [category, setCategory] = useState([]);
  const [isArticle, setIsArticle] = useState(false);

  const dispatch = useDispatch();
  const { files, videoFiles } = useContext(TableContext);
  const {
    // control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm();
  useEffect(() => {
    window.scrollTo(0, 0);
    setDashboardHeader('Edit BALANCE Library Details');
    interventionsId = params.interventionsId;
    const url = REACT_APP_API_FETCH_CATEGORYS_DROPDOWN; // api url
    fetch(url)
      .then((resp) => resp.json()) // calling url by method GET
      .then((resp) => {
        setCategory(resp.data.category.selectdata);
      });

    const actionData = {};
    actionData.actionUrl = `interventions/${interventionsId}`;
    actionData.actionMethod = 'get';
    dispatch(Entry(actionData)).then((resp) => {
      // delete resp.payload.id;
      console.log(resp);

      // eslint-disable-next-line
      setIsArticle(resp?.payload?.data?.intervention?.isArticle ? true : false);
      setUser(resp.payload.data.intervention);
      reset(resp.payload.data.intervention);
    });
  }, []);
  const timeRegex = /^\d{1,3}:\d{2}(:\d{2})?$/;

  // eslint-disable-next-line no-unused-vars
  function onSubmitdata(data) {
    let newFiles = [];
    if (isArticle) {
      newFiles = files;
    } else if (files.length !== 0 && videoFiles.length !== 0) newFiles = [files, videoFiles];
    else if (files && videoFiles.length === 0) newFiles = [files];
    else if (files.length === 0 && videoFiles) newFiles = [videoFiles];
    if (data.duration && !timeRegex.test(data.duration)) {
      setError('Time format is only allowed for duration, with the following format: hh:mm:ss.');
      document.getElementById('duration').focus();
      return;
    }
    setSubmitting(true);
    setError(null);
    console.log(newFiles);
    const apiData =
      newFiles.length > 0
        ? {
            title: data.title,
            category: data.category.label || user.category,
            description: data?.description ? data?.description : '',
            duration: data?.duration ? data?.duration : '',
            status: true,
            isArticle,
            File: newFiles,
            points: data?.points ? data?.points : 0,
          }
        : {
            title: data.title,
            category: data.category.label || user.category,
            description: data?.description ? data?.description : '',
            duration: data?.duration ? data?.duration : '',
            status: true,
            isArticle,
            // File: files,
            points: data?.points ? data?.points : 0,
          };
    data.actionUrl = `interventions/${params.interventionsId}`;
    data.actionMethod = 'patch';
    data.apiData = apiData;
    console.log(apiData);
    console.log(newFiles);
    dispatch(Entry(data)).then((resp) => {
      setSubmitting(false);
      if (resp.payload.code === 200) {
        navigate(-1);
        toast.success('BALANCE Library updated successfully');
        // eslint-disable-next-line max-len
      } else if (
        resp.payload.code === 401 ||
        resp.payload.code === 400 ||
        resp.payload.code === 403
      ) {
        setError(resp.payload.message);
        toast.error('Error');
      } else {
        navigate(-1);
      }
    });
  }

  const interventionTypeHandler = () => {
    setIsArticle((pre) => !pre);
  };

  return (
    <div className={styles[bodyStyle]}>
      <div
        className={styles[dashboardStyle]}
        style={{ minHeight: '100vh' }}
        id={styles.dashboardcont2}
      >
        <div className={styles[formthemeStyle]} id={styles.addform}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            value="Back"
            onClick={() => {
              navigate(-1);
            }}
            className={styles.arrowback}
          />
          <div className="container-fluid mt-5 edituserform">
            {user.length !== 0 ? (
              <form onSubmit={handleSubmit(onSubmitdata)}>
                <div className="row">
                  <div className="col-md-6 col-sm-6">
                    {user && (
                      <div>
                        <TextInput
                          className={styles.inputbox}
                          classNamedark={styles.inputbox1}
                          labelstyle={styles.formlabel}
                          label="Title"
                          fieldName="title"
                          placeHolder="Enter Title"
                          register={register}
                          errors={errors}
                          isRequired
                          mandatory
                          maximLength={75}
                        />
                      </div>
                    )}
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <SelectBox
                      className={styles.inputbox}
                      classNamedark={styles.inputbox1}
                      name="category"
                      label="Category"
                      labelstyle={styles.formlabel}
                      control={control}
                      register={register}
                      values={category}
                      errors={errors}
                      placeholder="Choose Category"
                      defaultvalue={category.find((r) => r.label === user.category).value}
                      mandatory
                      isRequired
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-6">
                    <TextAreaField
                      className={styles.inputbox}
                      classNamedark={styles.inputbox1}
                      labelstyle={styles.formlabel}
                      fieldName="description"
                      label="Description "
                      register={register}
                      errors={errors}
                      placeHolder="Description"
                      minimLength={false}
                      isRequired
                    />
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <TextInput
                      className={styles.inputbox}
                      classNamedark={styles.inputbox1}
                      labelstyle={styles.formlabel}
                      label="Duration "
                      fieldName="duration"
                      placeHolder="Enter Duration"
                      register={register}
                      errors={errors}
                      RegularExp
                      id="duration"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <NumberInput
                      className={styles.inputbox}
                      classNamedark={styles.inputbox1}
                      labelstyle={styles.formlabel}
                      label="Points"
                      fieldName="points"
                      placeHolder="Enter Points"
                      register={register}
                      errors={errors}
                      id="points"
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <label htmlFor="isArticle" className={styles.formlabel}>
                      Article
                    </label>
                    <div className="form-field">
                      <input
                        type="checkbox"
                        id="custom-checkbox"
                        style={{
                          marginRight: '5px',
                          width: '20px', // Increase the width
                          height: '20px',
                        }}
                        checked={isArticle}
                        onChange={interventionTypeHandler}
                      />
                    </div>
                  </div>
                </div>
                <div className="row py-3">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <label htmlFor="image" className={styles.formlabel}>
                      Image <span style={{ color: 'red' }}>*</span> : <span />
                    </label>
                    <MultipleFileUpload
                      id="imageupload"
                      accept={'image/*'}
                      section="Interventions"
                      editFiles={[{ path: user.image }]}
                    />
                  </div>
                  {!isArticle && (
                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <label htmlFor="video" className={styles.formlabel}>
                        Video <span style={{ color: 'red' }}>*</span> : <span />
                      </label>

                      <MultipleVideoUpload
                        editFiles={[{ path: user.video, extension: 'video' }]}
                        section="Interventions"
                        id="videoupload"
                        accept={'video/*'}
                      />
                    </div>
                  )}
                </div>
                <input
                  className={styles.formbtn}
                  type="submit"
                  disabled={submiting}
                  value={submiting ? 'Please wait..' : 'Update'}
                />
                {error && <h6 className={styles[errormsgStyle]}>{error}</h6>}
              </form>
            ) : (
              <div style={{ textAlign: 'center', marginTop: '130px', color: '#39979d' }}>
                <SpinnerDotted />
              </div>
            )}
            <br />
            <ToastContainer autoClose={2000} />
          </div>
        </div>
      </div>
    </div>
  );
});

export default InterventionsEdit;
